<template>
  <div :id="`colSortingWrapper-${sortKey}`" class="relative">
    <ClearLink
      class="inline-block mb-[-3px]"
      :element-id="`sort-${sortKey}`"
      :to-link="`javascript:;`"
      @click="$emit('handleThreeDotMenu')"
    >
      <SvgSprite class="w-3 h-3 ml-1 fill-gray-400" symbol="arrow-up-down" />
    </ClearLink>

    <ClearLink
      v-if="canRemoveCol"
      class="inline-block mb-[-3px]"
      :element-id="`close-${sortKey}`"
      :to-link="`javascript:;`"
      @click="$emit('toggleColumnFilter')"
    >
      <SvgSprite class="w-3 h-3 ml-1 fill-gray-400 opacity-0 hover:opacity-100" symbol="close" />
    </ClearLink>

    <div
      v-if="isOpen"
      class="z-50 top-6 right-0 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-24 dark:bg-gray-700 item-left"
    >
      <ul class="text-sm text-gray-700 mt-2 dark:text-gray-200">
        <li v-for="option in options" :key="option.action">
          <button
            class="block text-left text-xs pl-2 py-1 w-full hover:bg-blue-50 dark:hover:bg-gray-600 dark:hover:text-white"
            :class="selectedOption?.toLowerCase() === option.action ? 'bg-sky-50' : ''"
            @click="
              () => {
                $emit('handleSorting', option.action, sortKey);
              }
            "
          >
            {{ option.title }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
const options = [
  {
    action: 'asc',
    icon: 'asc',
    title: 'A - Z'
  },
  {
    action: 'desc',
    icon: 'desc',
    title: 'Z - A'
  }
];

defineEmits(['handleSorting', 'handleThreeDotMenu', 'toggleColumnFilter']);
defineProps({
  sortKey: {
    type: String,
    default: ''
  },
  selectedOption: {
    type: String,
    required: false,
    default: ''
  },
  isOpen: {
    type: Boolean,
    default: false
  },
  canRemoveCol: {
    type: Boolean,
    default: true
  }
});
</script>
